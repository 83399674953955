import { useExperienceStore } from '@/components/home/case-experience/store';
import { Close } from '@/components/icons';
import { CapsizedText as Text } from '@/components/text';
import { styled } from '@/stitches.config';

export function ToggleContent() {
  const { showContent, updateShowContent, updateExploreState } =
    useExperienceStore((state) => ({
      updateShowContent: state.updateShowContent,
      showContent: state.showContent,
      updateExploreState: state.updateExploreState,
    }));

  function onClickHandler() {
    if (!showContent) {
      updateExploreState('orb');
    } else {
      updateExploreState('cluster');
    }

    updateShowContent(!showContent);
  }

  return (
    <div>
      {showContent && (
        <BrowseButton onClick={onClickHandler}>
          <Text
            as="span"
            size={1}
            css={{
              display: 'block',
              transform: 'translateY(-1px)',
            }}
          >
            Explore by color
          </Text>
        </BrowseButton>
      )}

      {!showContent && (
        <CloseButton onClick={onClickHandler}>
          <Close />
        </CloseButton>
      )}
    </div>
  );
}

const CloseButton = styled('a', {
  position: 'fixed',
  top: 15, // same value as the header padding
  right: 15,
  border: '1px solid $foreground',
  color: '$foreground',
  padding: '$2',
  zIndex: 10000,
  transition: 'all .2s ease-in-out',
  cursor: 'pointer',

  '&:hover': {
    opacity: 0.65,
  },
});

const BrowseButton = styled('a', {
  position: 'fixed',
  right: 20,
  bottom: 16,
  border: '1px solid $foreground',
  color: '$foreground',
  padding: '$3 $3',
  zIndex: 9,
  transition: 'all .2s ease-in-out',
  cursor: 'pointer',
  maxWidth: '37vw',

  '&:hover': {
    color: '$foreground',
  },

  '@bp3': {
    display: 'none',
  },
});
