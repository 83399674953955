import create from 'zustand';
import { CONFIG } from './config';
import {
  CurrentPaletteType,
  CurrentStateType,
  ExploreStateType,
} from './types';

// This is the store that handles all the states across the components that are being used in the experience.

interface useExperienceStore {
  // Get and update the current state
  currentState: CurrentStateType;
  updateState: (newState: CurrentStateType) => void;

  // Get and update the sub states for the explore scene
  exploreState: ExploreStateType;
  updateExploreState: (newState: ExploreStateType) => void;

  // Get and update the active indexes that are being selected by the user
  // Also it is possible to reset the experience
  activeIndexes: number[];
  updateActiveIndex: (newIndex: number) => void;
  resetActiveIndex: () => void;

  hoverIndex: number | null;
  updateHoverIndex: (newIndex: number | null) => void;

  // Get and update the active palettes that is being selected based on the users selection
  activePalette: CurrentPaletteType | null;
  updateActivePalette: (palette: CurrentPaletteType | null) => void;

  // Get and update the content that is visible, this is the header text and the navigation
  showContent: boolean;
  updateShowContent: (boolean: boolean) => void;

  // Get and update if the experience could be clicked or hovered
  isClickable: boolean;
  updateIsClickable: (boolean: boolean) => void;
}

// Most of the functions here are just setting and getting the state, also the defaults
export const useExperienceStore = create<useExperienceStore>((set) => ({
  currentState: 'start',
  updateState: (newState: CurrentStateType) => set({ currentState: newState }),

  exploreState: 'cluster',
  updateExploreState: (newState: ExploreStateType) =>
    set({ exploreState: newState }),

  activeIndexes: [],
  updateActiveIndex: (index: number) =>
    set((state: { activeIndexes: number[] }) => {
      const newState = [...state.activeIndexes, index];

      // Have max items in the store, otherwise remove the first one again
      if (state.activeIndexes.length === CONFIG.AMOUNT_OF_ITEMS_BEFORE_MATCHING)
        newState.shift();

      return {
        activeIndexes: newState,
      };
    }),

  hoverIndex: null,
  updateHoverIndex: (newIndex: number | null) => set({ hoverIndex: newIndex }),

  activePalette: null,
  updateActivePalette: (palette: CurrentPaletteType | null) =>
    set({ activePalette: palette }),

  showContent: true,
  updateShowContent: (boolean: boolean) => set({ showContent: boolean }),

  isClickable: false,
  updateIsClickable: (boolean: boolean) => set({ isClickable: boolean }),

  resetActiveIndex: () => set({ activeIndexes: [] }),
}));
